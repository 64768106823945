import { b } from "@/b";
import { MEDIA_FRAGMENT, ResponsiveImage } from "@/components/ResponsiveImage";
import { Section } from "@/components/layout/Section";
import { Image, Link } from "@chakra-ui/next-js";
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Text,
  keyframes,
} from "@chakra-ui/react";
import { FragmentData } from "@gqlb/core";
import { useEffect, useState } from "react";
import FARM_TO_TABLE_IMG from "@/img/farm_to_table.png";

export const ChefSpecialProduct_Fragment = b.fragment(
  "ChefSpecialProduct",
  "Product",
  (b) => [
    b.id(),
    b.name(),
    b.slug(),
    b.description(),
    b.images((b) => [b.__fragment(MEDIA_FRAGMENT)]),
  ]
);

export const ChefSpecials_Fragment = b.fragment(
  "ChefSpecials",
  "Query",
  (b) => [
    b
      .tag({ name: "Chef Special" }, (b) => [
        b.id(),
        b.name(),
        b.products({ limit: 6 }, (b) => [
          b.__fragment(ChefSpecialProduct_Fragment),
        ]),
      ])
      .alias("chefSpecials"),
  ]
);

const ChefSpecialSectionInner: React.FC<{
  products: ReadonlyArray<FragmentData<typeof ChefSpecialProduct_Fragment>>;
}> = ({ products }) => {
  const pagingDuration = 10000;
  const [activeProductIndex, setActiveProductIndex] = useState(0);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setActiveProductIndex((prev) => (prev + 1) % products.length);
    }, pagingDuration);
    return () => {
      clearTimeout(timeout);
    };
  }, [activeProductIndex, products.length]);

  const product = products[activeProductIndex];

  const animateLTR = keyframes`
    from {
      left: 0%;
    }
    to {
      left: 100%;
    }
  `;

  return (
    <Grid
      gridTemplateColumns={{
        base: "1fr",
        lg: "1fr 1fr",
      }}
      flex="1"
      rowGap="6"
      columnGap="24"
    >
      <Box pos="relative">
        <ResponsiveImage
          image={product.images[0]}
          objectFit="cover"
          w="100%"
          aspectRatio={{
            base: "1",
            lg: "1.5",
          }}
        />
        <Image
          src={FARM_TO_TABLE_IMG}
          alt="Farm to Table"
          boxSize={{ base: "140px", md: "160px", xl: "220px" }}
          pos="absolute"
          bottom="0"
          right="0"
          zIndex="1"
        />
      </Box>

      <Box px="4">
        <Flex flexDir="row" alignItems="center" columnGap="4">
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              setActiveProductIndex((prev) => {
                if (prev === 0) {
                  return products.length - 1;
                }
                return prev - 1;
              });
            }}
            rounded="full"
            boxSize={{
              base: "8",
              xl: "12",
            }}
          >
            &lt;
          </Button>
          <Box flex="1">
            <Heading
              fontSize={{
                base: "2xl",
                xl: "4xl",
              }}
              textAlign={{
                base: "center",
              }}
              fontWeight={{
                base: "semibold",
              }}
            >
              Chef Specials
            </Heading>
            <Heading
              fontSize={{
                base: "sm",
                xl: "2xl",
              }}
              textAlign={{
                base: "center",
              }}
              fontWeight={{
                base: "regular",
              }}
              textTransform="uppercase"
              height={{
                base: "8",
                xl: "12",
              }}
            >
              {product.name}
            </Heading>
          </Box>
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              setActiveProductIndex((prev) => (prev + 1) % products.length);
            }}
            rounded="full"
            boxSize={{
              base: "8",
              xl: "12",
            }}
          >
            &gt;
          </Button>
        </Flex>

        <Box
          pos="relative"
          borderBottom="1px solid black"
          w="40%"
          mx="auto"
          my="4"
        >
          <Box
            pos="absolute"
            left="0"
            top="0"
            transform="translateY(-50%)"
            boxSize="5px"
            rounded="full"
            bg="black"
            animation={`${animateLTR} ${pagingDuration}ms linear 1 forwards`}
            key={product.id}
          />
        </Box>
        <Text textAlign="center" maxW="lg" mx="auto">
          {product.description}
        </Text>
        <Link
          href={`/product/${product.slug}`}
          display="flex"
          border="solid 2px black"
          rounded="full"
          w="fit-content"
          py="2"
          px="4"
          mx="auto"
          mt="4"
        >
          Order Now &gt;
        </Link>
      </Box>
    </Grid>
  );
};

export const ChefSpecialSection: React.FC<{
  data: FragmentData<typeof ChefSpecials_Fragment>;
}> = ({ data }) => {
  if (!data.chefSpecials) {
    return null;
  }
  return (
    <Section>
      <ChefSpecialSectionInner products={data.chefSpecials.products} />
    </Section>
  );
};
